import Image from "next/image";
import React from "react";
import ExternalLinkButton from "./ExternalLinkButton";
import IntegrationLogo from "./IntegrationLogos";
import { container_classname, sub_section_heading } from "./constants";

const IntegrationSection: React.FC<{ isLight?: boolean }> = ({ isLight = false }) => {
    const bgColor = isLight ? "white" : "linear-gradient(103deg, #EDF4FF 28.71%, #D2DDFF 84.45%, #84A2FF 136.43%)";
    return (
        <section className="lg:py-20 py-10" style={{ background: bgColor }}>
            <div className={`${container_classname} flex flex-col lg:gap-20 gap-6`}>
                <div className="flex lg:flex-row flex-col lg:justify-between gap-10">
                    <div className="lg:w-1/2 w-full lg:py-0 py-5">
                        <h2 className={`${sub_section_heading} mb-5`} style={{ fontFamily: "Degular Display" }}>
                            Connect your favorite tools. In a few clicks.
                        </h2>
                        <ExternalLinkButton href="/whatsapp-integrations" target="_blank" rel="noreferrer">
                            View all Integrations
                        </ExternalLinkButton>
                    </div>
                    <div className="lg:w-1/2 lg:flex hidden lg:items-center lg:justify-center" style={{ height: "163px" }}>
                        <Image height={163} width={500} src="https://assets.gallabox.com/gb-home/new-landing/integration/integration.png" alt="whatsapp icon" />
                    </div>
                </div>
            </div>
            <div className="lg:pt-20 lg:pb-0 py-6">
                <IntegrationLogo />
            </div>
        </section>
    );
};

export default IntegrationSection;
