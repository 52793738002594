import React from "react";
import { CTADarkButtons } from "./CTAButtons";
import { container_classname, sub_section_heading } from "./constants";

interface MetricsDataProps {
    metricValue: string;
    metricType: string;
}

const metricsData: MetricsDataProps[] = [
    {
        metricValue: "200M+",
        metricType: "Conversations powered",
    },
    {
        metricValue: "40M+",
        metricType: "Chatbot-driven conversations",
    },
    {
        metricValue: "20K+",
        metricType: "Global active users",
    },
];

interface CTASection {
    title: string;
    description?: string;
}

const CTASection: React.FC<CTASection> = (props) => {
    const { title, description } = props;
    return (
        <section
            className="lg:py-20 py-10"
            style={{
                background: "linear-gradient(101deg, #102565 -0.21%, #0A3FE7 38.51%, #0A3FE7 59.13%, #010E36 99.99%)",
            }}
        >
            <div className={`${container_classname} flex flex-col`}>
                <div className="lg:mb-24 mb-16">
                    <h2 className={`${sub_section_heading} lg:mb-6 mb-7`} style={{ color: "white", fontFamily: "Degular Display" }}>
                        {title}
                    </h2>
                    <p className="text-lg text-white lg:mb-9 mb-7">{description}</p>
                    <CTADarkButtons primaryButtonText="Start for free" />
                </div>
                <div className="flex lg:flex-row flex-col lg:gap-14 gap-12 lg:max-w-5xl">
                    {metricsData.map((metrics) => {
                        const { metricValue, metricType } = metrics;
                        return (
                            <div key={metricValue} className="text-white flex flex-col lg:gap-6 gap-5 lg:w-1/3 w-full">
                                <p className="text-6xl font-bold md:text-8xl" style={{ fontFamily: "Degular Variable" }}>
                                    {metricValue}
                                </p>
                                <p className="text-xl">{metricType}</p>
                            </div>
                        );
                    })}
                </div>
            </div>
        </section>
    );
};

export default CTASection;
