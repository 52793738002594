import Image from "next/image";
import React from "react";
import { container_classname, sub_section_heading } from "./constants";

const SpotlightSection: React.FC = () => {
    return (
        <section className="lg:py-20 py-14" style={{ backgroundColor: "#E6EEFF" }}>
            <div className={`${container_classname} flex flex-col gap-12`}>
                <h2 className={`${sub_section_heading} lg:max-w-4xl`} style={{ fontFamily: "Degular Display" }}>
                    Calls, emails, and SMS are not enough to drive conversions at scale
                </h2>
                <div className="flex flex-col lg:flex-row gap-5">
                    <div className="lg:w-1/3 w-full bg-white rounded-lg p-6 flex flex-col gap-6 items-start">
                        <Image width={84} height={84} src={"https://assets.gallabox.com/gb-home/new-landing/spotlight/down-trend.svg"} alt={"down-trend"} />
                        <p className="text-xl font-normal">Prospects drop off because the lead verification process is manual, lengthy, and chaotic.</p>
                    </div>
                    <div className="lg:w-1/3 w-full bg-white rounded-lg p-6 flex flex-col gap-6 items-start">
                        <Image width={84} height={84} src={"https://assets.gallabox.com/gb-home/new-landing/spotlight/sand-timer.svg"} alt={"sand-timer"} />
                        <p className="text-xl font-normal">Your team ends up wasting time on qualifying leads with repetitive questions instead of driving sales.</p>
                    </div>
                    <div className="lg:w-1/3 w-full bg-white rounded-lg p-6 flex flex-col gap-6 items-start">
                        <Image width={84} height={84} src={"https://assets.gallabox.com/gb-home/new-landing/spotlight/broken-chain.svg"} alt={"broken-chain"} />
                        <p className="text-xl font-normal">Leads and conversations are not tracked 24/7 as they lie scattered across multiple channels.</p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SpotlightSection;
