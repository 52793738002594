import React, { PropsWithChildren } from "react";

type Size = "sm" | "md";
const sizes: Record<Size, string> = {
    sm: "text-base",
    md: "text-xl",
};

type ExternalLinkButtonPropsType = Omit<React.JSX.IntrinsicElements["a"], "className">;

interface ExternalLinkButtonProps extends ExternalLinkButtonPropsType {
    size?: Size;
}

const ExternalLinkButton: React.FC<PropsWithChildren<ExternalLinkButtonProps>> = ({ children, style = {}, size = "md", ...rest }) => {
    return (
        <a className={`${sizes[size]} font-medium flex gap-2 w-fit`} style={{ color: "#3364FA", ...style }} target="_blank" {...rest}>
            {children}
        </a>
    );
};

export default ExternalLinkButton;
