import { PropsWithChildren, useEffect, useRef, useState } from "react";

interface LazyVideoProps extends React.DetailedHTMLProps<React.VideoHTMLAttributes<HTMLVideoElement>, HTMLVideoElement> {
    src: string;
    type: string;
    poster: string;
    isPaused?: boolean;
}

const LazyVideo: React.FC<PropsWithChildren<LazyVideoProps>> = ({ src, type, poster, isPaused, ...rest }) => {
    const videoRef = useRef<HTMLVideoElement>(null);
    const [isIntersecting, setIsIntersecting] = useState(false);

    useEffect(() => {
        const handleIntersection: IntersectionObserverCallback = (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setIsIntersecting(true);
                } else {
                    setIsIntersecting(false);
                }
            });
        };

        const observer = new IntersectionObserver(handleIntersection);
        const currentVideoRef = videoRef.current;

        if (currentVideoRef) {
            observer.observe(currentVideoRef);
        }

        return () => {
            if (currentVideoRef) {
                observer.unobserve(currentVideoRef);
            }
        };
    }, []);

    useEffect(() => {
        if (isIntersecting && videoRef.current && videoRef.current.paused) {
            videoRef.current.play().catch((error) => {
                console.error("Failed to play video:", error);
            });
        }
    }, [isIntersecting]);

    useEffect(() => {
        if (videoRef.current) {
            if (isPaused) {
                videoRef.current.pause();
            } else {
                videoRef.current.play().catch((err) => {
                    console.error("Error playing the video:", err);
                });
            }
        }
    }, [isPaused]);

    return (
        <video ref={videoRef} controls={false} muted loop playsInline poster={poster} {...rest}>
            <source src={src} type={type} />
        </video>
    );
};

export default LazyVideo;
