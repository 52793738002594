import LazyVideo from "@components/LandingPageComponents/LazyVideoComponent";
import React from "react";
import { LuArrowUpRight } from "react-icons/lu";
import { container_classname, sub_section_heading } from "./constants";
import { GBButtonWithIcon } from "./CTAButtons";

const keyFeatures: KeyFeatureCardProps[] = [
    {
        feature: "Lead capturing",
        title: "Collect verified leads without friction",
        mainDescription: "Ditch those annoying lead forms that cause user drop-offs.",
        subDescription:
            "Use Click-to-WhatsApp ads and widgets to drive prospects straight to your WhatsApp business account to make the most of your traffic; get verified numbers without a complicated user experience.",
        url: "https://gallabox.com/click-to-whatsapp-ads",
        mediaUrl: "https://assets.gallabox.com/gb-home/new-landing/key-feature/lead_capturing.webm",
        mediaType: "video/webm",
        bgColor: "#F2FFEA",
    },
    {
        feature: "Lead qualification",
        title: "Use AI chatbots to instantly filter prime prospects",
        mainDescription: "Quit wasting your sales team’s time on low-intent leads.",
        subDescription:
            "Build no-code AI chatbots to ask the right questions, collect essential lead info, and then route the prospects to the appropriate agent or sales rep — all on WhatsApp.",
        url: "https://gallabox.com/whatsapp-chatbot",
        mediaUrl: "https://assets.gallabox.com/gb-home/new-landing/key-feature/lead_qualification.webm",
        mediaType: "video/webm",
        bgColor: "#F5EEFF",
    },
    {
        feature: "Lead nurturing",
        title: "Run multi-touch WhatsApp campaigns to create new pipeline",
        mainDescription: "Don’t turn away prospects who may be potential buyers.",
        subDescription:
            "Keep your prospects engaged with broadcast and sequence campaigns for every buyer segment. Make your campaigns stand out with interactive buttons, rich media and contextual auto-replies.",
        url: "https://gallabox.com/whatsapp-broadcast",
        mediaUrl: "https://assets.gallabox.com/gb-home/new-landing/key-feature/lead_nurturing.webm",
        mediaType: "video/webm",
        bgColor: "#FFFADF",
    },
    {
        feature: "Lead conversion",
        title: "Close deals faster with full visibility and context",
        mainDescription: "Never lose track of a prospect or customer message.",
        subDescription:
            "Enable your sales team to access every WhatsApp conversation in a shared team inbox — with all context intact. Reduce sales cycles with quick responses (and in multiple languages) using Gallabox Gen AI and help them hit your targets every month.",
        url: "https://gallabox.com/whatsapp-shared-inbox",
        mediaUrl: "https://assets.gallabox.com/gb-home/new-landing/key-feature/lead_conversion.webm",
        mediaType: "video/webm",
        bgColor: "#E1FCFF",
    },
];

interface KeyFeatureCardProps {
    feature: string;
    title: string;
    mainDescription: string;
    subDescription: string;
    url: string;
    mediaUrl: string;
    mediaType: string;
    bgColor: string;
}

const KeyFeatureCardMobile: React.FC<KeyFeatureCardProps> = (props) => {
    const { feature, title, mainDescription, subDescription, url, mediaUrl, mediaType, bgColor } = props;
    return (
        <div className="w-full flex flex-col gap-12 pt-5 pb-10 justify-center items-center">
            <div className="w-full flex flex-col">
                <h6 className="text-base font-medium uppercase mb-2" style={{ color: "#3364FA" }}>
                    {feature}
                </h6>
                <h3 className={"text-3xl font-semibold text-gray-900"} style={{ fontFamily: "Degular Display" }}>
                    {title}
                </h3>

                <p className="text-xl mt-6" style={{ letterSpacing: "0.4px" }}>
                    {mainDescription}
                </p>
                <p className="text-xl my-3" style={{ letterSpacing: "0.4px" }}>
                    {subDescription}
                </p>
                <div className="py-3 w-fit">
                    <GBButtonWithIcon variant="primary-dark" size="md" href={url} target="_blank" rightIcon={LuArrowUpRight}>
                        Know more
                    </GBButtonWithIcon>
                </div>
            </div>
            <div className="w-full mx-4 flex items-center justify-center px-7 py-8 rounded-lg" style={{ backgroundColor: bgColor }}>
                <LazyVideo src={mediaUrl} type={mediaType} poster="" className="rounded-lg object-cover overflow-hidden" />
            </div>
        </div>
    );
};

const KeyFeaturesSection: React.FC<{ features?: KeyFeatureCardProps[] }> = ({ features = keyFeatures }) => {
    const sectionRefs = React.useRef<(HTMLDivElement | null)[]>([]);
    const rightRefs = React.useRef<(HTMLDivElement | null)[]>([]);

    React.useEffect(() => {
        const sectionObserver = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    const index = sectionRefs.current.findIndex((section) => section === entry.target);
                    const element = rightRefs.current?.[index];
                    if (index <= 0 || !element) return;

                    if (entry.isIntersecting) {
                        element.style.willChange = "transform";
                        element.style.transformStyle = "preserve-3d";
                        const offset = entry.boundingClientRect.top > 0 ? entry.intersectionRatio * 100 : 100;
                        element.style.transform = `translate3d(0, -${offset}%, 0)`;
                    } else {
                        element.style.willChange = "transform";
                        element.style.transformStyle = "preserve-3d";
                        element.style.transform = "translate3d(0, 0%, 0)";
                    }
                });
            },
            { threshold: Array.from({ length: 1001 }, (_, i) => i / 1000) }
        );

        sectionRefs.current.forEach((section) => {
            if (section) sectionObserver.observe(section);
        });

        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            sectionRefs.current.forEach((section) => {
                if (section) sectionObserver.unobserve(section);
            });
        };
    }, []);

    return (
        <section className={`${container_classname} lg:py-20 py-16`}>
            <div className="flex flex-col gap-10 mb-16">
                <h2 className={`${sub_section_heading} lg:w-4/5`} style={{ fontFamily: "Degular Display" }}>
                    Leverage Gallabox’s AI-powered messaging and automation platform
                </h2>
                <h3 className="lg:text-2xl text-lg font-medium">To convert the curious into customers on WhatsApp.</h3>
            </div>
            {/* Mobile View */}
            <div className="lg:hidden block">
                {features.map((feature, index) => (
                    <KeyFeatureCardMobile key={index} {...feature} />
                ))}
            </div>

            {/* Desktop View */}
            <div className="lg:flex hidden relative gap-20" style={{ height: `${features.length * 100}vh` }}>
                {/* Left Section */}
                <div className="w-1/2 relative">
                    {features.map((feature, index) => (
                        <div
                            key={index}
                            ref={(el) => {
                                sectionRefs.current[index] = el;
                            }}
                            className="h-screen flex flex-col justify-center"
                        >
                            <h6 className="text-base font-medium uppercase mb-2" style={{ color: "#3364FA" }}>
                                {feature.feature}
                            </h6>
                            <h3 className="text-5xl font-semibold" style={{ fontFamily: "Degular Display" }}>
                                {feature.title}
                            </h3>
                            <p className="text-xl mt-9">{feature.mainDescription}</p>
                            <p className="text-xl mt-4 mb-6">{feature.subDescription}</p>
                            <div className="py-3 w-fit">
                                <GBButtonWithIcon variant="primary-dark" size="md" href={feature.url} target="_blank" rightIcon={LuArrowUpRight}>
                                    Know more
                                </GBButtonWithIcon>
                            </div>
                        </div>
                    ))}
                </div>

                {/* Right Section */}
                <div className="w-1/2 relative">
                    <div className="rounded-lg sticky overflow-hidden" style={{ height: "80vh", top: "15vh" }}>
                        {features.map((feature, index) => (
                            <div
                                key={index}
                                ref={(el) => {
                                    rightRefs.current[index] = el;
                                }}
                                style={{
                                    backgroundColor: feature.bgColor,
                                    height: "80vh",
                                    inset: "100% auto auto 0",
                                    zIndex: index + 1,
                                    ...(index === 0 ? { top: "0%" } : {}),
                                    width: "100%",
                                }}
                                className={"absolute rounded-lg flex justify-center items-center p-10 ease-in-out overflow-hidden"}
                            >
                                <LazyVideo src={feature.mediaUrl} type={feature.mediaType} poster="" className="h-full object-contain" />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default KeyFeaturesSection;
