import "keen-slider/keen-slider.min.css";
import { KeenSliderPlugin, useKeenSlider } from "keen-slider/react";
import Image from "next/image";
import React from "react";
import { PiCaretCircleLeftThin, PiCaretCircleRightThin } from "react-icons/pi";
import ExternalLinkButton from "./ExternalLinkButton";
import { container_classname } from "./constants";

export const WheelControls: KeenSliderPlugin = (slider) => {
    let touchTimeout: ReturnType<typeof setTimeout>;
    let position: {
        x: number;
        y: number;
    };
    let wheelActive: boolean;

    function dispatch(e: WheelEvent, name: string) {
        position.x -= e.deltaY;
        position.y -= e.deltaX;
        slider.container.dispatchEvent(
            new CustomEvent(name, {
                detail: {
                    x: position.x,
                    y: position.y,
                },
            })
        );
    }

    function wheelStart(e: WheelEvent) {
        position = {
            x: e.pageX,
            y: e.pageY,
        };
        dispatch(e, "ksDragStart");
    }

    function wheel(e: WheelEvent) {
        dispatch(e, "ksDrag");
    }

    function wheelEnd(e: WheelEvent) {
        dispatch(e, "ksDragEnd");
    }

    function eventWheel(e: WheelEvent) {
        e.preventDefault();
        if (!wheelActive) {
            wheelStart(e);
            wheelActive = true;
        }
        wheel(e);
        clearTimeout(touchTimeout);
        touchTimeout = setTimeout(() => {
            wheelActive = false;
            wheelEnd(e);
        }, 50);
    }

    slider.on("created", () => {
        slider.container.addEventListener("wheel", eventWheel, {
            passive: false,
        });
    });
};

interface Feature {
    mediaUrl: string;
    title: string;
    description: string;
    url: string;
}

const FeatureCard: React.FC<Feature> = (props) => {
    return (
        <div className={"keen-slider__slide  flex flex-col gap-0 rounded"}>
            <div className="w-full rounded-t-lg overflow-hidden lg:h-64 h-44">
                <Image src={props.mediaUrl} alt={props.title} width={600} height={600} style={{ width: "100%", height: "100%", objectFit: "fill" }} />
            </div>
            <div className="w-full lg:h-44 h-52 bg-white p-6 rounded-b-lg flex flex-col">
                <h3 className="text-xl font-semibold mb-2" color="#151515">
                    {props.title}
                </h3>

                <p className="text-base mb-3 w-fit">{props.description}</p>
                <div className="flex-grow" />
                <ExternalLinkButton size="sm" href={props.url}>
                    Know more
                </ExternalLinkButton>
            </div>
        </div>
    );
};

const sampleData: Feature[] = [
    {
        mediaUrl: "https://assets.gallabox.com/gb-home/new-landing/feature/ctwa-new.png",
        title: "Click-to-WhatsApp Ads",
        description: "Get your audience from Facebook and Instagram ads to WhatsApp in a single tap.",
        url: "https://gallabox.com/click-to-whatsapp-ads",
    },
    {
        mediaUrl: "https://assets.gallabox.com/gb-home/new-landing/feature/flows-new.png",
        title: "WhatsApp Flows",
        description: "Build forms to collect data for surveys and drive outcomes like appointment booking and registrations.",
        url: "https://gallabox.com/whatsapp-flows",
    },
    {
        mediaUrl: "https://assets.gallabox.com/gb-home/new-landing/feature/payments.png",
        title: "WhatsApp Payments",
        description: "Enable payment workflows to ease your customers’ checkout experience on WhatsApp.",
        url: "https://gallabox.com/whatsapp-payments",
    },
    {
        mediaUrl: "https://assets.gallabox.com/gb-home/new-landing/feature/catalog.png",
        title: "Product Catalogs",
        description: "Set up a virtual shop by showcasing your products inside WhatsApp.",
        url: "https://gallabox.com/build-your-shop-on-whatsapp",
    },
];

const FeaturesSection: React.FC<{ features?: Feature[] }> = ({ features = sampleData }) => {
    const [currentSlide, setCurrentSlide] = React.useState(0);
    const [loaded, setLoaded] = React.useState(false);
    const [sliderRef, instanceRef] = useKeenSlider({
        mode: "free",
        drag: true,
        // rubberband: true,
        vertical: false,
        slides: {
            perView: 2.5,
            spacing: 20,
        },
        breakpoints: {
            "(max-width: 640px)": {
                slides: { perView: 1.1, spacing: 20 },
            },
        },
        initial: 0,
        slideChanged(slider) {
            setCurrentSlide(slider.track.details.rel);
        },
        created() {
            setLoaded(true);
        },
    });

    const disableLeftArrow = React.useMemo(() => currentSlide === 0, [currentSlide]);
    const disableRightArrow = React.useMemo(() => {
        const slidesLength = features.length;
        return slidesLength !== undefined && currentSlide === slidesLength - 1;
    }, [currentSlide, features]);

    return (
        <section
            className="lg:py-20 py-16"
            style={{
                background: "linear-gradient(283deg, #396AFF 0%, #000 98.82%)",
            }}
        >
            <div className={`${container_classname} flex flex-col lg:gap-12 gap-8`}>
                <div className="flex justify-between">
                    <div className="flex flex-col gap-6">
                        <h2 className={"lg:text-5xl text-3xl font-semibold text-white lg:max-w-5xl"} style={{ fontFamily: "Degular Display" }}>
                            Keep prospects close and customers closer with features that make the most of WhatsApp
                        </h2>
                    </div>
                    {loaded && instanceRef.current && (
                        <div className="lg:flex hidden text-white">
                            <PiCaretCircleLeftThin
                                style={{ cursor: disableLeftArrow ? "not-allowed" : "pointer" }}
                                size={60}
                                onClick={(e: any) => {
                                    if (!disableLeftArrow) e.stopPropagation() || instanceRef.current?.prev();
                                }}
                            />
                            <PiCaretCircleRightThin
                                style={{ cursor: disableRightArrow ? "not-allowed" : "pointer" }}
                                size={60}
                                onClick={(e: any) => {
                                    if (!disableRightArrow) e.stopPropagation() || instanceRef.current?.next();
                                }}
                            />
                        </div>
                    )}
                </div>
                <div ref={sliderRef} className="keen-slider w-full" style={{ overflow: "visible" }}>
                    {features.map((feature, index) => (
                        <FeatureCard key={index} {...feature} />
                    ))}
                </div>
                {loaded && instanceRef.current && (
                    <div className="lg:hidden mx-auto flex gap-1 text-white">
                        <PiCaretCircleLeftThin
                            style={{ cursor: disableLeftArrow ? "not-allowed" : "pointer" }}
                            size={60}
                            onClick={(e: any) => {
                                if (!disableLeftArrow) e.stopPropagation() || instanceRef.current?.prev();
                            }}
                        />
                        <PiCaretCircleRightThin
                            style={{ cursor: disableRightArrow ? "not-allowed" : "pointer" }}
                            size={60}
                            onClick={(e: any) => {
                                if (!disableRightArrow) e.stopPropagation() || instanceRef.current?.next();
                            }}
                        />
                    </div>
                )}
            </div>
        </section>
    );
};

export default FeaturesSection;
