import "keen-slider/keen-slider.min.css";
import { useKeenSlider } from "keen-slider/react";
import Image from "next/image";
import React from "react";

interface LogoObject {
    url: string;
    alt: string;
}

const integLogosData: LogoObject[] = [
    {
        url: "https://assets.gallabox.com/gb-home/integration-component-logos/razorpay.png",
        alt: "razorpay",
    },
    {
        url: "https://assets.gallabox.com/gb-home/integration-component-logos/shopify.png",
        alt: "shopify",
    },
    {
        url: "https://assets.gallabox.com/gb-home/integration-component-logos/hubspot.png",
        alt: "hubspot.png",
    },
    {
        url: "https://assets.gallabox.com/gb-home/integration-component-logos/zapier.png",
        alt: "zapier",
    },
    {
        url: "https://assets.gallabox.com/gb-home/integration-component-logos/zoho2.png",
        alt: "zoho2",
    },
    {
        url: "https://assets.gallabox.com/gb-home/integration-component-logos/leadsquare.png",
        alt: "leadsquare",
    },
    {
        url: "https://assets.gallabox.com/gb-home/integration-component-logos/facebook.png",
        alt: "facebook",
    },
    {
        url: "https://assets.gallabox.com/gb-home/integration-component-logos/woocommerce.png",
        alt: "woocommerce",
    },

    {
        url: "https://assets.gallabox.com/gb-home/integration-component-logos/kylas.png",
        alt: "kylas",
    },
    {
        url: "https://assets.gallabox.com/gb-home/integration-component-logos/shiprocket.png",
        alt: "shiprocket",
    },
    {
        url: "https://assets.gallabox.com/gb-home/integration-component-logos/googlesheets.png",
        alt: "googlesheets",
    },
    {
        url: "https://assets.gallabox.com/gb-home/integration-component-logos/cashfree.png",
        alt: "cashfree",
    },

    {
        url: "https://assets.gallabox.com/gb-home/integration-component-logos/payu.png",
        alt: "payu",
    },

    {
        url: "https://assets.gallabox.com/gb-home/integration-component-logos/webengage.png",
        alt: "webengage",
    },
];

const IntegrationLogo: React.FC<{ logos?: LogoObject[] }> = ({ logos }) => {
    const animation = { duration: 30000, easing: (t: any) => t };

    const [sliderRef] = useKeenSlider({
        breakpoints: {
            "(max-width: 790px)": {
                slides: { perView: 2, spacing: 10 },
            },
            "(max-width: 450px)": {
                slides: { perView: 2, spacing: 10, origin: "center" },
            },
        },
        slides: { perView: 8, spacing: 30 },
        loop: true,
        drag: true,
        created(s) {
            s.moveToIdx(5, true, animation);
        },
        updated(s) {
            s.moveToIdx(s.track.details.abs + 5, true, animation);
        },
        animationEnded(s) {
            s.moveToIdx(s.track.details.abs + 5, true, animation);
        },
    });

    return (
        <div ref={sliderRef} className="keen-slider">
            {(logos ? logos : integLogosData).map((e, i) => (
                <div className={`keen-slider__slide slider-card number-slide${i} flex items-center`} key={i}>
                    <Image width={200} height={96} src={e.url} alt={e.alt} />
                </div>
            ))}
        </div>
    );
};

export default IntegrationLogo;
