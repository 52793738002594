export const container_classname = "lg:max-w-7xl lg:mx-auto px-5 w-full";
export const sub_section_heading = "lg:text-6xl text-4xl font-semibold text-gray-900";

export interface ColumnItemsType {
    name: string;
    link?: string;
    externalLink?: string;
    isNew?: boolean;
}

export interface FooterDataProps {
    columnHeader: string;
    columnItems: ColumnItemsType[];
}

export const footerData1: FooterDataProps[] = [
    {
        columnHeader: "Features",
        columnItems: [
            {
                name: "WhatsApp Flows",
                link: "/whatsapp-flows",
            },
            {
                name: "WhatsApp AI Chatbot",
                link: "/whatsapp-ai-chatbot",
            },
            {
                name: "WhatsApp Shop",
                link: "/build-your-shop-on-whatsapp",
            },
            {
                name: "WhatsApp Business API",
                link: "/whatsapp-business-api",
            },

            {
                name: "WhatsApp Chatbot",
                link: "/whatsapp-chatbot",
            },
            {
                name: "WhatsApp Broadcast",
                link: "/whatsapp-broadcast",
            },
            {
                name: "Click to WhatsApp Ads",
                link: "/click-to-whatsapp-ads",
            },

            {
                name: "Shared Team Inbox",
                link: "/whatsapp-shared-inbox",
            },
            {
                name: "Drip Marketing",
                link: "/whatsapp-drip-marketing",
            },
            {
                name: "WhatsApp Payments",
                link: "/whatsapp-payments",
            },
        ],
    },
    {
        columnHeader: "Industry",
        columnItems: [
            {
                name: "Education",
                link: "/whatsapp-chatbot-for-edutech",
            },
            {
                name: "Travel",
                link: "/whatsapp-chatbot-for-travel-and-tourism",
            },
            {
                name: "Real Estate",
                link: "/whatsapp-chatbot-for-real-estate",
                isNew: true,
            },
            {
                name: "BFSI",
                link: "/banking-chatbots",
            },
            {
                name: "Healthcare",
                link: "/whatsapp-chatbot-for-health-wellness-brands",
            },
            {
                name: "ecommerce",
                link: "/whatsapp-integrations/ecommerce",
            },
        ],
    },
    {
        columnHeader: "Free tools",
        columnItems: [
            {
                name: "AI WhatsApp Template Generator",
                link: "/ai-whatsapp-template-generator",
            },
            {
                name: "WhatsApp Pricing Calculator",
                link: "/whatsapp-conversation-pricing-calculator",
                isNew: true,
            },
            {
                name: "WhatsApp Business Solution",
                link: "/whatsapp-business-api",
            },
            {
                name: "WhatsApp Chat Widget",
                link: "/whatsapp-chat-button",
            },
            {
                name: "WhatsApp Link Generator",
                link: "/whatsapp-link-generator",
            },
            {
                name: "WhatsApp QR Code Generator",
                link: "/whatsapp-qr-code-generator",
            },
            {
                name: "WhatsApp Chatbot Template",
                link: "/whatsapp-chatbot-templates",
            },
        ],
    },
    {
        columnHeader: "Resources",
        columnItems: [
            {
                name: "Documentation",
                externalLink: "https://docs.gallabox.com/",
            },
            {
                name: "Developer APIs",
                externalLink: "https://documenter.getpostman.com/view/17087523/Tzz7QJTJ",
            },
            {
                name: "Blog",
                externalLink: "/blog",
            },
            {
                name: "eBooks",
                externalLink: "/resources/downloadables",
            },
            {
                name: "Videos",
                externalLink: "https://www.youtube.com/c/Gallabox/videos",
            },
            {
                name: "Case Study",
                externalLink: "/case-study",
            },
            {
                name: "How-To Guides",
                externalLink: "https://guides.gallabox.com/",
            },
        ],
    },
];

export const footerData2: FooterDataProps[] = [
    {
        columnHeader: "Compare",
        columnItems: [
            {
                name: "with Wati",
                link: "/best-wati-alternative",
            },
            {
                name: "with Aisensy",
                link: "/best-aisensy-alternative",
            },
            {
                name: "with Rasayel",
                link: "/best-rasayel-alternative",
            },
            {
                name: "with Zoko",
                link: "/zoko-alternative",
            },
            {
                name: "with Verloop",
                link: "/verloop-alternative",
            },
            {
                name: "with Interakt",
                link: "/interakt-alternative",
            },
            {
                name: "with Infobip",
                link: "/infobip-alternative",
            },
            {
                name: "with GetChat",
                link: "/best-getchat-alternative",
            },
        ],
    },
    {
        columnHeader: "Integration",
        columnItems: [
            {
                name: "Calendly",
                link: "/whatsapp-integrations/calendly",
                isNew: true,
            },
            {
                name: "Zoho CRM",
                link: "/whatsapp-integrations/zoho-books",
                isNew: true,
            },
            {
                name: "Odoo",
                link: "/whatsapp-integrations/odoo",
                isNew: true,
            },
            {
                name: "MoEngage",
                link: "/whatsapp-integrations/moengage",
            },
            {
                name: "Shopify",
                link: "/whatsapp-integrations/shopify",
            },
            {
                name: "Zapier ",
                link: "/whatsapp-integrations/zapier",
            },
            {
                name: "Hubspot ",
                link: "/whatsapp-integrations/hubspot",
            },
            {
                name: "RazorPay",
                link: "/whatsapp-integrations/razorpay",
            },
            {
                name: "Google Sheets",
                link: "/whatsapp-integrations/google-sheets",
            },
        ],
    },
    {
        columnHeader: "Support",
        columnItems: [
            {
                name: "FAQ",
                externalLink: "https://docs.gallabox.com/?q=",
            },
            {
                name: "Raise a ticket",
                externalLink: "https://gallaboxsupport.zohodesk.in/portal/en/home",
            },
        ],
    },
    {
        columnHeader: "Company",
        columnItems: [
            {
                name: "About",
                link: "/about",
            },
            {
                name: "Customers",
                link: "/case-study",
            },
            {
                name: "Careers",
                externalLink: "https://angel.co/company/gallabox",
            },
            {
                name: "Contact Us",
                link: "/contact",
            },
            {
                name: "Partner with us",
                externalLink: "/gallabox-partner-program",
                isNew: true,
            },
        ],
    },
];
