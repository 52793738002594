import { OverlayProvider } from "@components/contexts/overlayProvider";
import { ThemeProvider } from "@components/contexts/themeProvider";
import { processEnv } from "@lib/processEnv";
import "@styles/colors.css";
import "@styles/common.css";
import "@styles/dark-mode.css";
import "@styles/emojipickercustom.css";
import "@styles/fonts.css";
import "@styles/index.css";
import "@styles/landing-animation.css";
import "@styles/prism.css";
import "@styles/screen-fixings.css";
import "@styles/screen.css";
import "@styles/toc.css";
import "@styles/emojipickercustom.css";
import "@styles/style-min.css";
import "@styles/webinar.css";
import "@styles/fader.css";
import "@styles/lineloader.css";
import { urlTracker } from "@utils/campaign-tracker";
import { AppProps } from "next/app";
import CookieConsent, { Cookies } from "react-cookie-consent";

import React, { useEffect } from "react";
import SnackbarProvider from "react-simple-snackbar";

const App: React.FC<AppProps> = ({ Component, pageProps }) => {
    useEffect(() => {
        const { cmsData } = pageProps || { cmsData: null };
        const { bodyClass } = cmsData || { bodyClass: null };
        const htmlTag = document.querySelector("html") as HTMLElement;
        htmlTag.className = bodyClass ? "casper" : "home";
    });

    useEffect(() => {
        urlTracker();
    }, []);

    const handleAcceptAll = () => {
        Cookies.set("consentCookie", "all", { expires: 30 });
    };

    const handleAcceptNecessary = () => {
        Cookies.set("consentCookie", "necessary", { expires: 30 });
    };

    return (
        <ThemeProvider {...processEnv.darkMode}>
            <OverlayProvider>
                <SnackbarProvider>
                    <Component {...pageProps} />
                </SnackbarProvider>
            </OverlayProvider>

            <CookieConsent
                buttonText="Accept All"
                enableDeclineButton
                declineButtonText="Accept Necessary"
                onAccept={handleAcceptAll}
                onDecline={handleAcceptNecessary}
                cookieName="consentCookie"
                expires={30}
                location="bottom"
                buttonStyle={{
                    backgroundColor: "#3364FA !important",
                    color: "white",
                    fontSize: "14px",
                    borderRadius: "4px",
                    margin: "5px 15px",
                }}
                declineButtonStyle={{
                    backgroundColor: "#3364FA !important",
                    color: "white",
                    fontSize: "14px",
                    borderRadius: "4px",
                    width: "100%",
                    margin: "5px 15px 15px 15px",
                }}
                flipButtons={true}
                declineButtonId="rcc-confirm-button"
            >
                <div>
                    <p className="font-bold text-black mb-2">Cookie Settings</p>
                    <div className="text-gray-700 font-normal text-base">
                        We use cookies to enhance your experience, analyze site traffic and deliver personalized content.{" "}
                        <a href="/privacy-policy" className="text-gray-500 w-full">
                            Read our Cookie <br />
                            Policy
                        </a>
                    </div>
                </div>
            </CookieConsent>
        </ThemeProvider>
    );
};

export default App;
